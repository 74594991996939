import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class FulfillmentReward extends Entity {
	static typeId = entityIdMap['chokuraku.fulfillment_reward'];

	/** 特典管理名称 */
	name!: string;
	/** 特典名 */
	rewardName!: string;
	/** 説明 */
	description!: string;
	/** 画像ファイル名 */
	image!: string;
	/** SKU管理番号 */
	sku!: string;
	/** 商品価格 */
	price!: number;
	/** 在庫数 */
	stock!: number;

	/** 割り当て店舗ID */
	shopIds?: string[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			rewardName: { isMandatory: true, type: 'string' },
			description: { isMandatory: true, type: 'string' },
			image: { isMandatory: true, type: 'string' },
			sku: { isMandatory: true, type: 'string' },
			price: { isMandatory: true, type: 'number' },
			stock: { isMandatory: true, type: 'number' },
			shopIds: { isMandatory: false, isArray: true, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			rewardName: this.rewardName,
			description: this.description,
			image: this.image,
			sku: this.sku,
			price: this.price,
			stock: this.stock,
			shopIds: this.shopIds,
		});
	}
}
